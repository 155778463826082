
.box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    background-color: #1f3540;
    cursor: pointer;
  }
  
  .box img {
    max-width: 100%;
    max-height: 100%;
  }
  
  @media (max-width: 768px) {
    .board {
      margin-top: 10px;
    }
    .box {
      width: 150px;
      height: 150px;
    }
  }
  
  @media (max-width: 576px) {
    .box {
      width: 130px;
      height: 130px;
    }
  }
  
  h1 img {
    height: 50px;
    margin-left: 10px;
  }
  
  @media (max-width: 386px) {
    .box {
      width: 105px;
      height: 105px;
    }
  }
  
  h1 img {
    height: 50px;
    margin-left: 10px;
  }
  